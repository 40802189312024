import type { FC, ReactChild } from 'react';
import { useLayoutEffect, useRef } from 'react';

import { setTooltipPositionInViewport } from './helpers/setTooltipPositionInViewport';
import { TooltipArrow, TooltipDescription } from './Tooltip.theme';

interface Props {
    description: ReactChild | undefined;
    id: string;
    width: 'fixed' | 'contentWidth';
}

const Tooltip: FC<Props> = ({ description, id, width }: Props) => {
    const refTooltip = useRef<HTMLSpanElement>(null);

    useLayoutEffect(() => {
        const tooltipElement = refTooltip.current;

        if (!tooltipElement) {
            return;
        }

        const onResize = (): void => {
            setTooltipPositionInViewport(tooltipElement);
        };

        onResize();
        window.addEventListener('resize', onResize, false);

        return (): void => {
            window.removeEventListener('resize', onResize, false);
        };
    }, []);

    return (
        <span id={id} role="tooltip" tabIndex={-1}>
            <TooltipDescription ref={refTooltip} width={width}>
                {description}
            </TooltipDescription>
            <TooltipArrow />
        </span>
    );
};

export default Tooltip;
