import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import type { ComponentProps, JSX, KeyboardEvent, ReactChild, SyntheticEvent } from 'react';
import { useCallback, useRef, useState } from 'react';

import Tooltip from './Tooltip';
import { TooltipWithTrigger, TooltipWrapper } from './TooltipIcon.theme';

const checkIsIconDefinition = (maybeIcon: IconDefinition | JSX.Element | string): maybeIcon is IconDefinition => {
    return !!(maybeIcon as IconDefinition)?.iconName;
};

interface Props extends ComponentProps<'span'> {
    ariaLabel?: string | false;
    description: ReactChild | undefined;
    icon?: IconDefinition | JSX.Element | string;
    width?: 'fixed' | 'contentWidth';
    timeout?: number;
    onShowTooltip?: () => void;
    onHideTooltip?: () => void;
}

export const TooltipIcon = ({
    ariaLabel = 'frontend.tooltip.trigger-label',
    children,
    description,
    icon = faQuestionCircle,
    tabIndex = 0,
    width = 'fixed',
    timeout = 0,
    onShowTooltip,
    onHideTooltip,
    ...htmlProps
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const [triggeredBy, setTriggeredBy] = useState<'focus' | 'mouse' | null>(null);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);

    const id = triggeredBy ? `tooltipicon-description-${triggeredBy}` : undefined;
    const iconElement = checkIsIconDefinition(icon) ? <Icon icon={icon} width={14} height={14} /> : icon;

    const showTooltip = useCallback(
        (event: SyntheticEvent): void => {
            if (triggeredBy) {
                return;
            }

            onShowTooltip?.();
            if (timeout) {
                timeoutId.current = setTimeout(() => setTriggeredBy(null), timeout);
            }
            setTriggeredBy(event.type === 'focus' ? 'focus' : 'mouse');
        },
        [triggeredBy, onShowTooltip, timeout],
    );

    const hideTooltip = useCallback(
        (event: SyntheticEvent): void => {
            const isTriggeredByFocus = triggeredBy === 'focus' && event.type !== 'blur';
            const isTriggeredByMouse = triggeredBy === 'mouse' && event.type !== 'mouseleave';

            if (!triggeredBy || isTriggeredByFocus || isTriggeredByMouse) {
                return;
            }

            onHideTooltip?.();
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
                timeoutId.current = null;
            }
            setTriggeredBy(null);
        },
        [onHideTooltip, timeoutId, triggeredBy],
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLSpanElement>): void => {
            if (triggeredBy && event.key === 'Escape') {
                setTriggeredBy(null);
            }
        },
        [triggeredBy],
    );

    return (
        <TooltipWithTrigger {...htmlProps}>
            <TooltipWrapper
                aria-describedby={id}
                aria-label={ariaLabel === false ? undefined : t(ariaLabel)}
                onFocus={showTooltip}
                onBlur={hideTooltip}
                onKeyDown={onKeyDown}
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                role={tabIndex > -1 ? 'button' : undefined}
                tabIndex={tabIndex}
            >
                {iconElement}
            </TooltipWrapper>
            {triggeredBy && id ? <Tooltip id={id} description={description} width={width} /> : null}
        </TooltipWithTrigger>
    );
};
