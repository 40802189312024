import { getIconStylesForInteractiveElement } from '@domains/shared/helpers/styles/getIconStylesForInteractiveElement';
import type { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

/*
 * These styles are strictly related to LinkTextButton.theme.tsx!
 * Any changes added here must also be added also to LinkTextButton.theme.tsx file.
 */

interface ButtonProps {
    icon: 'left' | 'right' | 'none';
    isInline: boolean;
}

export const PrimaryTextButton = styled.button<ButtonProps>`
    padding: ${({ isInline }): string => (isInline ? '0' : '12px 16px')};
    border: none;
    outline: none;
    background: none;
    color: ${({ theme }): string => theme.deprecated.domains.shared.textButton.primary.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
    line-height: 1;
    text-align: center;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }

    &:disabled {
        &,
        &:hover,
        &:focus {
            color: ${({ theme }): string => theme.deprecated.domains.shared.textButton.disabled.color};
            text-decoration: none;
            cursor: not-allowed;
        }
    }

    ${({ icon }): SerializedStyles | undefined => getIconStylesForInteractiveElement(icon)}
`;

export const SecondaryTextButton = styled(PrimaryTextButton)`
    color: ${({ theme }): string => theme.deprecated.domains.shared.textButton.secondary.color};
`;
