import styled from '@emotion/styled';

export const TooltipWithTrigger = styled.span`
    display: inline-block;
    position: relative;
    flex: 0 0 auto;
    margin: 0 0 0 0.3em;
    line-height: 1;
`;

export const TooltipWrapper = styled.span`
    outline: none;
`;
