import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import { ActionLinkButton, GhostLinkButton, PrimaryLinkButton, SecondaryLinkButton } from './LinkButton.theme';

type LinkButtonVariant = 'primary' | 'secondary' | 'ghost' | 'action';

interface Props extends Omit<ComponentProps<'a'>, 'size'> {
    isDisabled?: boolean;
    shouldFitContainer?: boolean;
    icon?: 'left' | 'right' | 'none';
    size?: 'small' | 'medium';
    variant?: LinkButtonVariant;
}

const LINK_BUTTON = {
    primary: PrimaryLinkButton,
    secondary: SecondaryLinkButton,
    ghost: GhostLinkButton,
    action: ActionLinkButton,
} as const;

/*
 * These components are strictly related to Button.tsx!
 * Any changes added here must also be added also to Button.tsx file.
 */

const LinkButtonElement: ForwardRefRenderFunction<HTMLAnchorElement, Props> = (
    {
        isDisabled = false,
        shouldFitContainer = false,
        icon = 'none',
        size = 'medium',
        variant = 'primary',
        children,
        ...htmlProps
    },
    ref,
) => {
    const StyledLinkButton = LINK_BUTTON[variant];

    return (
        <StyledLinkButton
            {...htmlProps}
            tabIndex={isDisabled ? -1 : 0}
            isDisabled={isDisabled}
            shouldFitContainer={shouldFitContainer}
            icon={icon}
            size={size}
            ref={ref}
        >
            {children}
        </StyledLinkButton>
    );
};

export const LinkButton = forwardRef<HTMLAnchorElement, Props>(LinkButtonElement);
