import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export type IconPosition = 'left' | 'right' | 'none';

export const getIconStylesForInteractiveElement = (icon: IconPosition): SerializedStyles | undefined => {
    if (icon === 'none') {
        return;
    }

    const marginDirection = icon === 'left' ? 'right' : 'left';

    return css`
      svg {
          margin-${marginDirection}: 8px;
      }
  `;
};
