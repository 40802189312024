import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import { PrimaryTextButton, SecondaryTextButton } from './TextButton.theme';

type TextButtonVariant = 'primary' | 'secondary';

interface Props extends ComponentProps<'button'> {
    icon?: 'left' | 'right' | 'none';
    isInline?: boolean;
    variant?: TextButtonVariant;
}

const TEXT_BUTTON = {
    primary: PrimaryTextButton,
    secondary: SecondaryTextButton,
} as const;

/*
 * These components are strictly related to LinkTextButton.tsx!
 * Any changes added here must also be added also to LinkTextButton.tsx file.
 */

const TextButtonElement: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
    { variant = 'primary', icon = 'none', isInline = false, children, ...htmlProps },
    ref,
) => {
    const StyledTextButton = TEXT_BUTTON[variant];

    return (
        <StyledTextButton {...htmlProps} icon={icon} isInline={isInline} ref={ref}>
            {children}
        </StyledTextButton>
    );
};

export const TextButton = forwardRef<HTMLButtonElement, Props>(TextButtonElement);
