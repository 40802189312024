import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';

export const TooltipArrow = styled.span`
    display: block;
    position: absolute;
    z-index: ${LAYER.x8};
    top: -7px;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, 0);
    border: 7px solid transparent;
    border-bottom-width: 0;
    border-top-color: ${({ theme }): string => theme.deprecated.domains.shared.tooltipIcon.tooltip.backgroundColor};
    outline: none;
    pointer-events: none;
`;

interface TooltipDescriptionProps {
    width: 'fixed' | 'contentWidth';
}

export const TooltipDescription = styled.span<TooltipDescriptionProps>`
    display: block;
    position: absolute;
    z-index: ${LAYER.x7};
    top: -6px;
    left: -5px;
    width: ${({ width }): string => (width === 'contentWidth' ? 'auto' : '200px')};
    padding: 12px;
    transform: translate(0, -100%);
    outline: none;
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.tooltipIcon.tooltip.backgroundColor};
    box-shadow: ${({ theme }): string => theme.deprecated.domains.shared.tooltipIcon.tooltip.boxShadow};
    color: ${({ theme }): string => theme.deprecated.domains.shared.tooltipIcon.tooltip.color};
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;
    white-space: normal;
    pointer-events: none;
`;
