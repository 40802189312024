import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import { ActionButton, GhostButton, PrimaryButton, SecondaryButton } from './Button.theme';
import { ButtonVariant } from './buttonVariant';

export interface Props extends Omit<ComponentProps<'button'>, 'size'> {
    shouldFitContainer?: boolean;
    icon?: 'left' | 'right' | 'none';
    size?: 'small' | 'medium';
    type?: 'button' | 'submit' | 'reset';
    variant?: ButtonVariant;
}

const BUTTON = {
    [ButtonVariant.Primary]: PrimaryButton,
    [ButtonVariant.Secondary]: SecondaryButton,
    [ButtonVariant.Ghost]: GhostButton,
    [ButtonVariant.Action]: ActionButton,
} as const;

/*
 * These components are strictly related to LinkButton.tsx!
 * Any changes added here must also be added also to LinkButton.tsx file.
 */

const ButtonElement: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
    {
        shouldFitContainer = false,
        icon = 'none',
        size = 'medium',
        variant = ButtonVariant.Primary,
        type = 'button',
        children,
        ...htmlProps
    },
    ref,
) => {
    const StyledButton = BUTTON[variant];

    return (
        <StyledButton
            {...htmlProps}
            shouldFitContainer={shouldFitContainer}
            icon={icon}
            size={size}
            type={type}
            ref={ref}
        >
            {children}
        </StyledButton>
    );
};

export const Button = forwardRef<HTMLButtonElement, Props>(ButtonElement);
