/**
 * Make sure that a tooltip doesn't beyond the right edge of the screen.
 */
export const setTooltipPositionInViewport = (tooltipElement: HTMLElement): void => {
    const { left, width } = tooltipElement.getBoundingClientRect();
    const viewportWidthWithoutScrollbar = document.documentElement.clientWidth;
    const positionLeftWithoutMargin = left - Number.parseInt(tooltipElement.style.marginLeft || '0', 10);
    let newMarginLeft = 0;

    if (positionLeftWithoutMargin + width > viewportWidthWithoutScrollbar) {
        const rightViewportMargin = 5;

        newMarginLeft = viewportWidthWithoutScrollbar - width - rightViewportMargin - positionLeftWithoutMargin;
    }

    tooltipElement.style.marginLeft = `${newMarginLeft}px`;
};
