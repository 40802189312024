import type { IconPosition } from '@domains/shared/helpers/styles/getIconStylesForInteractiveElement';
import { getIconStylesForInteractiveElement } from '@domains/shared/helpers/styles/getIconStylesForInteractiveElement';
import type { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

/*
 * These styles are strictly related to Button.theme.tsx!
 * Any changes added here must also be added also to Button.theme.tsx file.
 */

interface Props {
    isDisabled: boolean;
    shouldFitContainer: boolean;
    icon: IconPosition;
    size: 'small' | 'medium';
}

const DefaultLinkButton = styled.a<Props>`
    display: ${({ shouldFitContainer }): string => (shouldFitContainer ? 'block' : 'inline-block')};
    width: ${({ shouldFitContainer }): string => (shouldFitContainer ? '100%' : 'auto')};
    padding: ${({ size }): string => (size === 'medium' ? '12px 16px' : '8px 12px')};
    transition: background-color 0.15s ease-in-out;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    outline: none;
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
    line-height: 1;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    ${({ isDisabled }): string => (isDisabled ? 'pointer-events: none;' : '')}

    ${({ icon }): SerializedStyles | undefined => getIconStylesForInteractiveElement(icon)}
`;

export const PrimaryLinkButton = styled(DefaultLinkButton)`
    border-color: ${({ theme }): string => theme.deprecated.atoms.button.primary.default.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.atoms.button.primary.default.backgroundColor};
    color: ${({ theme }): string => theme.deprecated.atoms.button.primary.default.color};

    &:hover {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.primary.hover.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.primary.hover.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.primary.hover.color};
    }

    &:focus {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.primary.focus.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.primary.focus.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.primary.focus.color};
    }

    &:active {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.primary.active.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.primary.active.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.primary.active.color};
    }

    ${({ theme, isDisabled }): string =>
        isDisabled
            ? `
            &,
            &:hover,
            &:focus,
            &:active {
                background-color: ${theme.deprecated.atoms.button.primary.disabled.backgroundColor};
                border-color: ${theme.deprecated.atoms.button.primary.disabled.borderColor};
                color: ${theme.deprecated.atoms.button.primary.disabled.color};
            }`
            : ''};
`;

export const GhostLinkButton = styled(DefaultLinkButton)`
    padding: ${({ size }): string => (size === 'medium' ? '11px 15px' : '7px 11px')};
    border-width: 2px;
    border-color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.default.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.default.backgroundColor};
    color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.default.color};

    &:hover {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.hover.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.hover.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.hover.color};
    }

    &:focus {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.focus.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.focus.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.focus.color};
    }

    &:active {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.active.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.active.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.ghost.active.color};
    }

    ${({ theme, isDisabled }): string =>
        isDisabled
            ? `
            &,
            &:hover,
            &:focus {
                border-color: ${theme.deprecated.atoms.button.ghost.disabled.borderColor};
                background-color: ${theme.deprecated.atoms.button.ghost.disabled.backgroundColor};
                color: ${theme.deprecated.atoms.button.ghost.disabled.color};
            }`
            : ''}
`;

export const ActionLinkButton = styled(DefaultLinkButton)`
    border-color: ${({ theme }): string => theme.deprecated.atoms.button.action.default.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.atoms.button.action.default.backgroundColor};
    color: ${({ theme }): string => theme.deprecated.atoms.button.action.default.color};

    &:hover {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.action.hover.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.action.hover.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.action.hover.color};
    }

    &:focus {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.action.focus.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.action.focus.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.action.focus.color};
    }

    &:active {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.action.active.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.action.active.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.action.active.color};
    }

    ${({ theme, isDisabled }): string =>
        isDisabled
            ? `
            &,
            &:hover,
            &:focus {
                border-color: ${theme.deprecated.atoms.button.action.disabled.borderColor};
                background-color: ${theme.deprecated.atoms.button.action.disabled.backgroundColor};
                color: ${theme.deprecated.atoms.button.action.disabled.color};
            }`
            : ''}
`;

export const SecondaryLinkButton = styled(DefaultLinkButton)`
    border-color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.default.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.default.backgroundColor};
    color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.default.color};

    &:hover {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.hover.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.hover.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.hover.color};
    }

    &:focus {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.focus.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.focus.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.focus.color};
    }

    &:active {
        border-color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.active.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.active.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.atoms.button.secondary.active.color};
    }

    ${({ theme, isDisabled }): string =>
        isDisabled
            ? `
            &,
            &:hover,
            &:focus {
                border-color: ${theme.deprecated.atoms.button.secondary.disabled.borderColor};
                background-color: ${theme.deprecated.atoms.button.secondary.disabled.backgroundColor};
                color: ${theme.deprecated.atoms.button.secondary.disabled.color};
            }`
            : ''}
`;
