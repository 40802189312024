import type { BasicPluralTranslation } from '@lib/i18n/types/plural';

/**
 * @deprecated Please use `getPluralTranslationVariant` instead.
 */
export const getTranslationVariant = (value: number, translationVariant: BasicPluralTranslation): string => {
    const rest10 = value % 10;
    const rest100 = value % 100;

    if (value === 1) {
        return translationVariant.one;
    }

    if (rest10 >= 2 && rest10 <= 4 && (rest100 < 12 || rest100 > 14)) {
        return translationVariant.few;
    }

    return translationVariant.many;
};
